import Typography from "@mui/material/Typography";
import "./Home.css";
import "../fonts.css";
import VideoPlayer from './VideoPlayer';
// import React, { useEffect, useState } from 'react';
import React, { useEffect } from 'react';
import homepage_image from '../assets/logosvg.svg'
import { useAuth } from './AuthContext';
import FAQ from './faq';
import Carousel from './Carousel';

import { IconButton } from '@mui/material';

import instaLogo from "../assets/instagram.svg"
import tiktokLogo from "../assets/tiktok.svg"
import emailLogo from "../assets/email.svg"


const imagesContext = require.context('./gallery_photos', false, /\.(png|jpg)$/);
const carouselImages = imagesContext.keys().map(imagesContext);


function Home() {

  return (
    <div className="homepage">
        <div class="container">
        <div className="logo-container" style={{ width: "100%", alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center" }}>
          <img style={{ width: "100%", height: "auto" }} src={homepage_image} alt="SAIL 2024 Logo" />
        </div>

          <p class="home_text" style={{ color: "white" }}>April 13-14, 2024</p>
        </div>

        <div className="surroundingBox" style={{ width: "100%", display: "flex", flexDirection: "column", alignContent: "center", alignItems: "center" }}>
          <div className="bus-information" style={{ paddingLeft: "5vw", paddingRight: "5vw", 
              display: "flex", 
              flexDirection: "column",
              justifyContent: "center",
              textAlign: "center",
              border: "2px solid purple",
              borderRadius: "10px",
              boxShadow: "0 0 5px blue, 0 0 10px purple",
              width: "40%",
          }}>
            <a href="https://illinois.zoom.us/j/86585503333?pwd=UGdEcWpmWUZ5NkZubXo3ajVLc0xRQT09">
              <h1 color="white">VIRTUAL SAIL EVENT LINK</h1>
            </a>
          </div>
        </div>
        

        <div class="container" style={{marginTop: "60px"}}>
          <div style={{ width: "40vw", alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "-5%", marginTop: "5%" }}>
            <p class="home_title">Schedule</p>
          </div>
          <p class="home_subtitle">Day 1</p>
          <table class="schedule-table">
            <tr>
              <th class="table_head">Time</th>
              <th class="table_head">Student Activities</th>
            </tr>
            <tr>
              <td>8:00am</td>
              <td>Check-in, Breakfast, and RSO fair</td>
            </tr>
            <tr>
              <td>9:30am</td>
              <td>Opening Ceremony<br/><span class="note" style={{ fontFamily: "JetBrainsMono" }}>(Room 1404)</span></td>
            </tr>
            <tr>
              <td>10:00am</td>
              <td>Class #1</td>
            </tr>
            <tr>
              <td>11:00am</td>
              <td>Class #2<br/></td>
            </tr>
            <tr>
              <td rowspan="2">12:00pm</td>
              <td>Lunch (Group X)<br/><span class="note" style={{ fontFamily: "JetBrainsMono" }}>(Room 1404)</span></td>
            </tr>
            <tr>
              <td>Tour (Group Y)</td>
            </tr>
            <tr>
              <td>1:00pm</td>
              <td>Lunch (Group Y)<br/>Tour (Group X)</td>
            </tr>
            <tr>
              <td>2:00pm</td>
              <td>Class #3</td>
            </tr>
            <tr>
              <td rowspan="2">3:00pm</td>
              <td>Prospective Student Q&A Panel</td>
            </tr>
            <tr>
              <td>Admitted Student Q&A Panel</td>
            </tr>
            <tr>
              <td>4:00pm</td>
              <td>Professor Wade Talk<br/><span class="note" style={{ fontFamily: "JetBrainsMono" }}>(Room 1404)</span></td>
            </tr>
          </table>

          <p class="home_subtitle">Parent Track</p>
          <table class="schedule-table">
            <tr>
              <th class="table_head">Time</th>
              <th class="table_head">Parent Activities</th>
            </tr>
            <tr>
              <td>8:00am</td>
              <td>Breakfast</td>
            </tr>
            <tr>
              <td>9:30am</td>
              <td>Opening Ceremony<br/><span class="note" style={{ fontFamily: "JetBrainsMono" }}>(Room 1404)</span></td>
            </tr>
            <tr>
              <td>10:00am</td>
              <td>Parent Q & A<br/><span class="note" style={{ fontFamily: "JetBrainsMono" }}>(Room 1404)</span></td>
            </tr>
            <tr>
              <td>11:00am</td>
              <td>Free to Explore Campus!<br/></td>
            </tr>
          </table>

          <p class="home_subtitle">Day 2</p>
          <table class="schedule-table">
            <tr>
              <th class="table_head">Time</th>
              <th class="table_head">Student Activities</th>
            </tr>
            <tr>
              <td>12:00pm</td>
              <td>
                <a href="https://illinois.zoom.us/j/86585503333?pwd=UGdEcWpmWUZ5NkZubXo3ajVLc0xRQT09">
                  <h2>Opening Ceremony</h2>
                </a>
              </td>
            </tr>
            <tr>
              <td>12:30pm</td>
              <td>Virtual Class #1</td>
            </tr>
            <tr>
              <td>1:30pm</td>
              <td>Virtual Class #2</td>
            </tr>
            <tr>
              <td>2:30pm</td>
              <td>
                <a href="https://illinois.zoom.us/j/86585503333?pwd=UGdEcWpmWUZ5NkZubXo3ajVLc0xRQT09">
                  <h2>Q&A Panel</h2>
                </a>
              </td>
            </tr>
            <tr>
              <td>3:30pm</td>
              <td>
                <a href="https://illinois.zoom.us/j/86585503333?pwd=UGdEcWpmWUZ5NkZubXo3ajVLc0xRQT09">
                  <h2>Closing Ceremony</h2>
                </a>
              </td>
            </tr>
          </table>
        </div>
        
        <div class="container">
          <div style={{ width: "40vw", alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center" }}>
            <p class="home_title">Gallery</p>
          </div>
          <div class="carousel_container">
            <div style={{ width: "40vw", alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center" }}>
              <Carousel images={carouselImages} />
            </div>
          </div>
        </div>

        <footer className="footer colored-footer">
          <div style={{ alignContent: "center", alignItems: "center", display: "flex", justifyContent: "center", marginBottom: "-5%", marginTop: "5%" }}>
            <p class="home_subtitle">Contact Us</p>
          </div>
          <div className="footer-container">
            <div className="footer-buttons">
              <IconButton color="primary" onClick={() => window.open("https://www.tiktok.com/@illinoissail", "_blank")}>
                <img src={tiktokLogo} alt="TikTok" style={{ width: '50px', height: '50px' }}/>
              </IconButton>
              <IconButton color="primary" onClick={() => window.open("https://www.instagram.com/illinois_cs_sail/?hl=en", "_blank")}>
                <img src={instaLogo} alt="Instagram" style={{ width: '50px', height: '50px' }}/>
              </IconButton>
              <IconButton color="primary" onClick={() => window.open("mailto:sail.cs.illinois@gmail.com", "_blank")}>
                <img src={emailLogo} alt="Email" style={{ width: '50px', height: '50px' }}/>
              </IconButton>
            </div>
          </div>
        </footer>
    </div>
  );
};

export default Home;


